<template>
  <core-section id="lets-talk">
    <core-heading>Let's talk.</core-heading>

    <v-col
      cols="12"
      md="5"
      class="mb-4 pr-md-5"
    >
      <core-subheading class="text-uppercase">
        Und was können wir für Sie tun?
      </core-subheading>

      <core-text class="mb-5">
        Gerne nehmen wir uns Zeit für Sie. Schreiben Sie uns einfach hier über das Kontaktformular, wir melden uns umgehend. <p />
        Ein Telefonat ist ebenso willkommen.
      </core-text>

      <core-subheading class="text-uppercase mb-3">
        Schicken Sie uns eine Nachricht
      </core-subheading>

      <v-form @submit.prevent="sendEmail">
        <v-text-field
          v-model="name"
          solo-inverted
          flat
          label="Name"
        />

        <v-text-field
          v-model="email"
          solo-inverted
          flat
          label="Email"
        />

        <v-text-field
          v-model="subject"
          solo-inverted
          flat
          label="Betreff"
        />

        <v-textarea
          v-model="message"
          solo-inverted
          flat
          label="Nachricht"
        />

        <v-btn
          class="ma-0"
          color="primary"
          type="submit"
        >
          Absenden
        </v-btn>
      </v-form>
    </v-col>

    <v-col
      cols="12"
      md="4"
      class="text-left"
    >
      <core-subheading class="text-uppercase">
        Kontaktadresse
      </core-subheading>

      <social-contacts />

      <v-list
        class="transparent"
        three-line
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-icon
              class="mr-5"
              color="primary"
              size="48"
            >
              mdi-map-marker
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Address
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ schema.basics.location.address }}<br>
              {{ schema.basics.location.city }}, {{ schema.basics.location.postalCode }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon
              size="48"
              color="primary"
              class="mr-5"
            >
              mdi-email
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Email
            </v-list-item-title>
            <v-list-item-subtitle v-text="schema.basics.email" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon
              size="48"
              color="primary"
              class="mr-5"
            >
              mdi-phone
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Phone
            </v-list-item-title>
            <v-list-item-subtitle v-text="schema.basics.phone" />
            <v-list-item-subtitle v-text="schema.basics.phonemobil" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
              <a href="https://www.freelancermap.de/freelancer-verzeichnis/profile/entwicklung/59885-profil-hartmut-kalk-senior-softwarearchitekt.html)" target="_blank" title="Profil von Hartmut Kalk auf www.freelancermap.de">
              <img src="https://www.freelancermap.de/images/widgets/button/findenSieMich.png">
              </a>
        </v-list-item>
      </v-list>
    </v-col>
  </core-section>
</template>

<script>
  import emailjs from 'emailjs-com'
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'LetsTalk',
    components: {
      SocialContacts: () => import('@/components/SocialContacts'),
    },
    data () {
      return {
        name: '',
        email: '',
        message: '',
        subject: '',
      }
    },
    computed: {
      ...mapState('app', ['schema']),
    },
    methods: {
      sendEmail (e) {
        try {
          var templateParams = { name: this.name, email: this.email, message: this.message, subject: this.subject }
          emailjs.send('service_gitadm_001', 'template_bslvzng', templateParams, 'user_4tFDtFBJr5KOX0EdGaisI')
          // emailjs.sendForm('service_gitadm_001', 'template_bslvzng', e.target, 'user_4tFDtFBJr5KOX0EdGaisI', { name: this.name, email: this.email, message: this.message })
        } catch (error) {
          console.log({ error })
        }
        this.name = ''
        this.email = ''
        this.message = ''
        this.subject = ''
      },
    },
  }
</script>
